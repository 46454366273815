import { HashLink } from 'react-router-hash-link';
import {
  BrowserRouter as Router
} from "react-router-dom";

import EnquiryModal from '../enquiry/EnquiryModal';

function Navigation() {
	return (
    <Router forceRefresh={true}>
      <HashLink  smooth to="/#about" >ABOUT</HashLink >
      <HashLink  smooth to="/#services" >SERVICES</HashLink >
      <HashLink  smooth to="/#team" >OUR TEAM</HashLink >
      <HashLink  smooth to="#contact" >CONTACT</HashLink >
      <EnquiryModal variant="contained"/>
    </Router>
	);
}
export default Navigation;
