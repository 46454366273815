import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import Navigation from './Navigation'
import SmallNav from './SmallNav'

import variables from '../../constants.module.scss';

import './Header.scss';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

export default function Header() {
  const classes = useStyles();

  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <AppBar position="fixed" style={{background: variables.primarycolor}}>
        <Toolbar>
          <Hidden mdUp>
            <SmallNav />
          </Hidden>
          <Hidden smDown>
          <Typography variant="h6" className={classes.title}>
            <Link to="/" className="btn btn-primary">
              {t('site_title')}
            </Link>
          </Typography>
            <Navigation />
          </Hidden>          
        </Toolbar>
      </AppBar>
    </div>
  );
}
