import React from 'react';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

import ServiceTemplate from '../service_template/ServiceTemplate';

import CivilLawImage from '../../../images/lokayukta.jpg'

export default function Lokayukta() {

  const { t } = useTranslation();
  
  return(
    <ServiceTemplate
      title={t('services.lokayukta.title')}
      description={parse(t('services.lokayukta.description'))}
      image={CivilLawImage}
    />
  )
}
