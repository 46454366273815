import React from 'react';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

import Header from '../header/Header'
import { ScrollToTopOnMount } from '../ScrollToTopOnMount';

import './Disclaimer.scss'

export default function Disclaimer() {
  const { t } = useTranslation();

  return (
    <div id="disclaimer" className="service-template">
      <ScrollToTopOnMount />
      <Header />
      <div className="section-caption">
        <h2 className="custom-underline">
          {t('disclaimer.title')}
        </h2>
      </div>
      <div data-aos="fade-up" className="disclaimer-wrapper">
        {parse(t('disclaimer.description'))}
      </div>
    </div>
  )
}
