import React from 'react';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

import ServiceTemplate from '../service_template/ServiceTemplate';

import CommercialDisputesImage from '../../../images/commercial-disputes.jpg'

import './CommercialDisputes.scss'

export default function CommercialDisputes() {

  const { t } = useTranslation()
  
  return(
    <ServiceTemplate
      title={t('services.commercial_disputes.title')}
      description={parse(t('services.commercial_disputes.description'))}
      image={CommercialDisputesImage}
    />
  )
}
