import React from 'react';
import { useTranslation } from 'react-i18next';

import './Team.scss';

export default function Team() {

  const { t } = useTranslation();

  return(
    <div id='team' name='about'>
      <div data-aos="fade-up">
        <div className="section-caption">
          <h2 className="custom-underline">
            {t('team.title')}
          </h2>
        </div>
        <div className="team-content-wrapper">
          <div className="team-member-list">
            <div className="team-member-list-item" style={{fontSize: "larger"}}>
              <b>Mr. JOSH RAJAN</b>
              <span className="sub-text">, MA LLB</span>
              <p>Senior Advocate, Legal Consultant</p>
            </div>
            <div className="team-member-list-item">
              <b>Mr. ANIL PHILIPOSE</b>
              <span className="sub-text">, MSW LLB</span>
              <p>Advocate, Legal Consultant</p>
            </div>
            <div className="team-member-list-item">
              <b>Mr. P BANI</b>
              <span className="sub-text">, BSc LLM</span>
              <p>Advocate, Legal Consultant</p>
            </div>
            <div className="team-member-list-item">
              <b>Ms. SHYNI R</b>
              <span className="sub-text">, BA LLB</span>
              <p>Advocate, Legal Consultant</p>
            </div>
            <div className="team-member-list-item">
              <b>Mr. P SANJEEVAN</b>
              <span className="sub-text">, BSc LLB</span>
              <p>Advocate, Legal Consultant</p>
            </div>
            <div className="team-member-list-item">
              <b>Ms. LEKSHMI</b>
              <span className="sub-text">, MA LLB</span>
              <p>Advocate, Legal Consultant</p>
            </div>
            <div className="team-member-list-item">
              <b>Mr. AMAL TOM</b>
              <span className="sub-text">, BCom LLB</span>
              <p>Advocate, Legal Consultant</p>
            </div>
            <div className="team-member-list-item">
              <b>Mr. Abin Sunny</b>
              <p>Advocate Clerk</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

