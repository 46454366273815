import React from 'react';
import { useTranslation } from 'react-i18next';
import Hidden from '@material-ui/core/Hidden';

import CivilLawImage from '../../../images/civil-law.jpg'

import Header from '../../../components/header/Header'
import { ScrollToTopOnMount } from '../../../components/ScrollToTopOnMount';

import CivilLawLargeScreen from './CivilLawLargeScreen';
import CivilLawSmallScreen from './CivilLawSmallScreen';

import './CivilLaw.scss';

export default function CivilLaw() {
  const { t } = useTranslation();

  return(
    <div id={t('services.civil_law.title').replace(/\s+/g, '-').toLowerCase()} className="service-template">
      <ScrollToTopOnMount />
      <Header />
      <div style={{backgroundImage: `url(${CivilLawImage})`}} className="service-image">
        <div className="image-caption">
          CIVIL LAW
        </div>
      </div>   
      <div data-aos="fade-up" className="service-wrapper">
        <div className="section">
          <div className="section-caption"><h2 className="custom-underline">{t('services.civil_law.title')}</h2></div>
          <div className="section-content">
            {t('services.civil_law.short_description')}
          </div>
        </div>
        <Hidden mdUp>
          <CivilLawSmallScreen />
        </Hidden>
        <Hidden smDown>
          <CivilLawLargeScreen />
        </Hidden>
      </div>
    </div>
  )
}
