import React from 'react';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

import ServiceTemplate from '../service_template/ServiceTemplate';

import ConsumerDisputesImage from '../../../images/consumer-disputes.jpg'


export default function ConsumerDisputes() {
  const { t } = useTranslation();
  
  return(
    <ServiceTemplate
      title={t('services.consumer_disputes.title')}
      description={parse(t('services.consumer_disputes.description'))}
      image={ConsumerDisputesImage}
    />
  )
}
