import React from 'react';
import { faCopyright } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import {
  Link,
  BrowserRouter as Router
} from "react-router-dom";
import Hidden from '@material-ui/core/Hidden';

import Contact from '../contact/Contact'
import { ROUTES } from '../../routes';

import './Footer.scss';

export default function Footer() {

  const { t } = useTranslation();

  return(
    <div id="footer-wrapper">
      <Contact />
      <Hidden mdUp>
        <div id="sm-footer">
          <div className="footer-links">
            <Router forceRefresh={true}>
              <Button variant="text" id="disclaimer-button">
                <Link to={ROUTES["TermsAndConditions"].path} className="footer-text">{"TERMS AND CONDITIONS &   PRIVACY POLICY"}</Link>
              </Button>
              | 
              <Button variant="text" id="disclaimer-button">
                <Link to={ROUTES["Disclaimer"].path}>DISCLAIMER</Link>
              </Button>
            </Router>
          </div>
          <div className="footer-text">
            <FontAwesomeIcon icon={faCopyright} />
            {t('footer.copyright')}
          </div>
        </div>
      </Hidden>
      <Hidden smDown>
        <div id="footer">
          <div className="footer-text">
            <FontAwesomeIcon icon={faCopyright} />
            {t('footer.copyright')}
          </div>
          <div className="footer-links">
            <Router forceRefresh={true}>
              <Button variant="text" id="disclaimer-button">
                <Link to={ROUTES["TermsAndConditions"].path} className="footer-text">{"TERMS AND CONDITIONS &   PRIVACY POLICY"}</Link>
              </Button>
              | 
              <Button variant="text" id="disclaimer-button">
                <Link to={ROUTES["Disclaimer"].path}>DISCLAIMER</Link>
              </Button>
            </Router>
          </div>
        </div>
      </Hidden>
    </div>
  );
}
