import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import EnquiryForm from './EnquiryForm'
import emailjs from 'emailjs-com';
import {
  Link,
  BrowserRouter as Router
} from "react-router-dom";

import { ROUTES } from '../../routes';

import './EnquiryModal.scss';

export default function EnquiryModal(props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    document.querySelector('body').style.paddingRight = 0;
    document.querySelector('body').style.overflow = 'visible';
    setOpen(false);
  };

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('gmail', 'template_9vocz12', e.target, 'user_GiuknrIEnXdoI4l49LH52')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
    e.target.reset();
      handleClose()
  }

  return (
    <div>
      <Button variant={props.variant} className="enquiry-button" onClick={handleClickOpen}>
        ENQUIRY
      </Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" id="enquiry-modal">
        <form onSubmit={sendEmail}>
          <DialogTitle id="form-dialog-title">Enquire</DialogTitle>
          <DialogContent>
            <DialogContentText>
            To connect with us, send us an enquiry and we will get back to you. 
            <div className="small-text">
              <Router forceRefresh={true}>
                Upon filling this form, you agree to our <Link to={ROUTES["TermsAndConditions"].path}>Privacy Policy</Link>.
              </Router>
            </div>
            </DialogContentText>
            <EnquiryForm />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button type="submit" color="primary">
              Send Enquiry
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
