import React from 'react';
import TextField from '@material-ui/core/TextField';
import { useForm, useFormState  } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

const schema = yup.object().shape({
  Name: yup.string().required(),
  Email: yup.string().email().required(),
  Phone: yup.number()
            .typeError("Please enter a valid Phone number")
            .test('len', 'Must be atleast 8 digits', val => !val || (val && val.toString().length >= 8 ))
            .required("Phone number is a required field"),
  Enquiry: yup.string().required(),
});

export default function EnquiryForm() {
  const { register, control } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema)
  });

  const { errors } = useFormState({
    control
  });

  return (
    <div>
      <TextField
        {...register("Name")}
        error={errors.Name}
        helperText={errors.Name && errors.Name.message}
        margin="dense"
        id="name"
        label="Name"
        type="text"
        variant="outlined"
        fullWidth
      />
      <TextField
        {...register("Email")}
        error={errors.Email}
        helperText={errors.Email && errors.Email.message}
        margin="dense"
        id="email"
        label="Email"
        type="text"
        variant="outlined"
        fullWidth
      />
      <TextField
        {...register("Phone")}
        error={errors.Phone}
        helperText={errors.Phone && errors.Phone.message}
        margin="dense"
        id="phone"
        label="Phone Number"
        type="text"
        variant="outlined"
        fullWidth
      />
      <TextField
        {...register("Enquiry")}
        error={errors.Enquiry}
        helperText={errors.Enquiry && errors.Enquiry.message}
        multiline
        margin="dense"
        id="enquiry"
        label="Enquiry Details"
        type="text"
        variant="outlined"
        fullWidth
      />      
    </div>
  )
}
