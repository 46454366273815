import React from 'react';
import { useTranslation } from 'react-i18next';
import Hidden from '@material-ui/core/Hidden';

import Header from '../../../components/header/Header';
import FamilyLawLargeScreen from './FamilyLawLargeScreen';
import FamilyLawSmallScreen from './FamilyLawSmallScreen';
import FamilyLawImage from '../../../images/family-law-12.jpeg';
import { ScrollToTopOnMount } from '../../../components/ScrollToTopOnMount';

import './FamilyLaw.scss';

export default function FamilyLaw() {
  const { t } = useTranslation();

  return(
    <div id={t('services.family_law.title').replace(/\s+/g, '-').toLowerCase()} className="service-template">
      <ScrollToTopOnMount />
      <Header />
      <div style={{backgroundImage: `url(${FamilyLawImage})`}} className="service-image">
        <div className="image-caption">
          FAMILY LAW
        </div>
      </div>   
      <div data-aos="fade-up" className="service-wrapper">
        <div className="section">
          <div className="section-caption"><h2 className="custom-underline">{t('services.family_law.title')}</h2></div>
          <div className="section-content">
            {t('services.family_law.short_description')}
          </div>
        </div>
        <Hidden mdUp>
          <FamilyLawSmallScreen />
        </Hidden>
        <Hidden smDown>
          <FamilyLawLargeScreen />
        </Hidden>
      </div>
    </div>
  )
}
