import React from 'react';

import './Reviews.scss';

import { Box, Card, CardContent, Typography, Avatar, Container, Grid2 } from "@mui/material";
import { styled } from "@mui/system";
import profilepic1 from '../../images/reviews/review1.png';
import profilepic2 from '../../images/reviews/review2.png';
import profilepic3 from '../../images/reviews/review3.png';

const StyledCard = styled(Card)(({ theme }) => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
  "&:hover": {
    transform: "translateY(-10px)",
    boxShadow: "0 12px 20px rgba(0, 0, 0, 0.1)",
  },
  borderRadius: "16px",
  overflow: "hidden",
}));

const testimonials = [
  {
    id: 1,
    name: "Adlen Varghese",
    designation: "Marketing Director",
    review: `I  consulted with *JN law Associate*  firm for a pathway settlement, and I am pleased to share my experience.
    From the outset,  Mr.Josh Rajan sir demonstrated exceptional professionalism and expertise. He took the time to explain the settlement process clearly and answered all my questions promptly. His communication was exceptional , keeping me informed every step of the way.
    
    His  strategic approach was evident, and I felt confident in their ability to negotiate on my behalf. Ultimately, he secured a settlement that exceeded my expectations.
    
    I also appreciated the transparency regarding fees and timelines, which made the entire process smoother.
    
    Mr. Josh Rajan sir played a crucial role during the arguments and counters, combining clarity in communication with unwavering dedication. His expertise and strategic insights were instrumental in guiding me toward a successful outcome in my case. I truly appreciate his support and commitment throughout the process.
    
    Overall, I highly recommend JN law associate firm for anyone seeking assistance with a pathway settlement or civil cases. His dedication and skill truly made a difference in my case.`,
    avatar: profilepic1
  },
  {
    id: 2,
    name: "Chandra Sekhar",
    designation: "Tech Lead",
    review: `I got commendable service and positive verdict for a case that i fought for establishing Easement By Grant on a pathway that has been provided for me in my title deed. JN Associates did a sincere effort in presenting legal points logically before the honorable court.`,
    avatar: profilepic2
  },
  {
    id: 3,
    name: "Aswathy Mathew",
    designation: "Product Manager",
    review: `One of the best lawyers I have met. He studied my civil case deeply. He handled my case very brilliantly and sincerely and give me the right guidance through out.I highly recommend this firm..Listens patiently and understands to the core of your problem and also explains all aspects and probabilities clearly. Very open,and supportive.The best among those I have communicated with and also his team is very active and prompt in work.Heartful thankyou for the sucess in my case `,
    avatar: profilepic3
  }
];

export default function Reviews() {
  return (
    <Container maxWidth="lg" sx={{ py: 8 }}>
      <div className="section-caption">
        <h2 className="custom-underline">
          Client Testimonials
        </h2>
      </div>
      <Typography variant="h5" align="center" color="text.secondary" paragraph>
        Discover what our clients say about their experience working with us
      </Typography>

      <Grid2 container spacing={4} sx={{ mt: 4 }}>
        <Grid2 size={{xs: 12, sm: 12, md: 6}} key={1}>
          <StyledCard>
            <CardContent>
              <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                <Avatar
                  src={testimonials[0].avatar}
                  alt={testimonials[0].name}
                  sx={{ width: 56, height: 56, mr: 2 }}
                />
                <Box>
                  <Typography variant="h6" component="div">
                    {testimonials[0].name}
                  </Typography>
                  <Typography variant="subtitle2" color="text.secondary">
                    {testimonials[0].designation}
                  </Typography>
                </Box>
              </Box>
              <Typography variant="body1" color="text.secondary" paragraph>
                {testimonials[0].review}
              </Typography>
            </CardContent>
          </StyledCard>
        </Grid2>
        <Grid2 size={{xs: 12, sm: 12, md: 6}} key={1}>
          <Grid2 container spacing={4} sx={{ mt: 4 }}>
            {testimonials.map((testimonial) => {
              if(testimonial.id === 1) {
                return null
              }

              return (
                <Grid2 size={{xs: 12, sm: 12, md: 12}} key={testimonial.id}>
                  <StyledCard>
                    <CardContent>
                      <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                        <Avatar
                          src={testimonial.avatar}
                          alt={testimonial.name}
                          sx={{ width: 56, height: 56, mr: 2 }}
                        />
                        <Box>
                          <Typography variant="h6" component="div">
                            {testimonial.name}
                          </Typography>
                          <Typography variant="subtitle2" color="text.secondary">
                            {testimonial.designation}
                          </Typography>
                        </Box>
                      </Box>
                      <Typography variant="body1" color="text.secondary" paragraph>
                        {testimonial.review}
                      </Typography>
                    </CardContent>
                  </StyledCard>
                </Grid2>
              )
            })}
          </Grid2>
        </Grid2>
      </Grid2>
    </Container>
  );
};



