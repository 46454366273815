import React from 'react';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

import ServiceTemplate from '../service_template/ServiceTemplate';

import ConveyanceLawImage from '../../../images/conveyance-law.jpg';

import './Conveyance.scss';

export default function Conveyance() {

  const { t } = useTranslation()
  
  return(
    <ServiceTemplate
      title={t('services.conveyance.title')}
      description={parse(t('services.conveyance.description'))}
      image={ConveyanceLawImage}
    />
  )
}
