import { Component } from "react";
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { HashLink } from 'react-router-hash-link';
import {
  BrowserRouter as Router
} from "react-router-dom";
import { withTranslation } from 'react-i18next';

import $ from 'jquery'; 

import './SmallNav.scss'
class SmallNav extends Component {
  constructor(props) {
    super(props);
    this.state = {isToggleOn: true};

    // This binding is necessary to make `this` work in the callback
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.setState(prevState => ({
      isToggleOn: !prevState.isToggleOn
    }));
  }

  render(){
    const classes = makeStyles((theme) => ({
      root: {
        flexGrow: 1,
      },
      menuButton: {
        marginRight: theme.spacing(2),
      },
      title: {
        flexGrow: 1,
      },
    }));

    let menu;
    let icon;;
    const { t } = this.props;

    if(this.state.isToggleOn){
      $('.MuiToolbar-root').css("display", "flex")
      $('#home').css("margin-top", "0")

      menu = (
        <div>
        </div>
      )
      icon = (
        <MenuIcon />
      )
    } else {
      $('.MuiToolbar-root').css("display", "block")
      $('#home').css("margin-top", "138px")

      menu = (
      <div>
        <Router forceRefresh={true}>
          <div className="about-nav-link">
            <HashLink onClick={this.handleClick} smooth to="/#about" >ABOUT</HashLink >
          </div>
          <div className="services-nav-link">
            <HashLink onClick={this.handleClick} smooth to="/#services" >SERVICES</HashLink >
          </div>
          <div className="team-nav-link">
            <HashLink onClick={this.handleClick} smooth to="/#team" >OUR TEAM</HashLink >
          </div>
          <div className="contact-nav-link">
            <HashLink onClick={this.handleClick} smooth to="#contact" >CONTACT</HashLink >
          </div>
        </Router>
      </div>
      )
      icon = (
        <CloseIcon style={{textAlign: "left"}}/>
      )
    }

    return(
      <span id="small-nav">
      <IconButton onClick={this.handleClick} edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
        {icon}        
      </IconButton>
      <Typography variant="h6" className={classes.title}>
        {t('site_title')}
      </Typography>
      {menu}
      </span>
    )
  }
}

export default withTranslation()(SmallNav)