import React from 'react';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

import ServiceTemplate from '../service_template/ServiceTemplate';

import LawOfContractImage from '../../../images/law-of-contract2.jpg'

export default function LawOfContract() {

  const { t } = useTranslation();
  
  return(
    <ServiceTemplate
      title={t('services.law_of_contract.title')}
      description={parse(t('services.law_of_contract.description'))}
      image={LawOfContractImage}
    />
  )
}

