import React from 'react';
import parse from 'html-react-parser';

export default function FamilyLawSmallScreen() {

  return(
    <div data-aos="fade-up">      
      <div className="section-caption">
        <h5 className="custom-underline1">
          Divorce                      
        </h5>
      </div>
      <div className='description'>
        Dissolution of marriage by a decree of divorce (contested or by mutual consent)
      </div>  
      <hr/> 
      <div className="section-caption">
        <h5 className="custom-underline1">
          Maintenance/Interim Maintenance/Alimony
        </h5>
      </div>
      <div className='description'>
        {parse("Maintenance/Interim Maintenance/Alimony for wife, children, aged parents, senior citizens \n Maintenance can be claimed under the respective personal laws by those who are unable to maintain themselves. Maintenance can be claimed by children, aged parents, wife, senior citizens and such is awarded depending upon the financial capabilities and status of the parties.")}
      </div> 
      <hr/>
      <div className="section-caption">
        <h5 className="custom-underline1">
          Custody of children
        </h5>
      </div>
      <div className='description'>
        {parse("Permanent custody, interim custody, visitorial rights, custody during vacations \n\n Parties can claim for physical custody or legal custody of children. Welfare of the child is the paramount consideration which determines the custody.")}
      </div>
      <hr/>
      <div className="section-caption">
        <h5 className="custom-underline1">
          Restitution of Conjugal Rights
        </h5>
      </div>
      <div className='description'>
        When either of the spouses without reasonable excuse withdraws from the society of the other, the aggrieved party can apply for restitution.
      </div> 
      <hr/>
      <div className="section-caption">
        <h5 className="custom-underline1">
          Registration of Marriages
        </h5>
      </div>
      <div className='description'>
        Includes registration of marriages under the Hindu Marriage Act. 1955 and Special Marriage Act, 1954. Persoans hailing from different cultural and religious backgrounds may often enter into marriages and in such cases external help may be called for counselling and registration of marriages.
      </div>
      <hr/>
      <div className="section-caption">
        <h5 className="custom-underline1">
          Matrimonial property rights
        </h5>
      </div>
      <div className='description'>
        {parse("Property was gifted by the parents of the bride at the time of marriage in joint ownership of the spouses.\n\nIf any dispute arises, the wife can claim the entire property.")}
      </div> 
      <hr/>
      <div className="section-caption">
        <h5 className="custom-underline1">
          Domestic Violence
        </h5>
      </div>
      <div className='description'>
        The provisions under the Protection of Women from Domestic Violence Act, 2005 provides remedies covering protection orders, residential orders, custody of children, maintenance, compensation, alternate accomodation, and protection of women from domestic violence. These problems require immediate assistance and coupled effort of professional expertise to achieve urgent legal remedy and will be attended to by our team of lawyers.
      </div>     
    </div>
  )
}