import React from 'react';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

import ServiceTemplate from '../service_template/ServiceTemplate';

import CriminalLawImage from '../../../images/criminal-law-2.jpg';

export default function CriminalSuits() {

  const { t } = useTranslation()
  
  return(
    <ServiceTemplate
      title={t('services.criminal_suits.title')}
      description={parse(t('services.criminal_suits.description'))}
      image={CriminalLawImage}
    />
  )
}
