import React from 'react';

import './CivilLaw.scss';

export default function CivilLawLargeScreen() {

  return(  
    <table data-aos="fade-up">
      <tbody>
        <tr>
          <td>
            <div className="section-caption">
              <h5 className="custom-underline1">
                Suits under the Specific Relief Act 1963 (as amended by Act 18 of 2018)
              </h5>
            </div>
          </td>
          <td className="description">
            Suits relating to:
            <ul style={{marginTop: 0}}>
              <li>Recovery of possession of property</li>
              <li>Specific performance of contracts</li>
              <li>Rectification of instruments</li>
              <li>Rescission of contracts</li>
              <li>Cancellation of instruments</li>
              <li>Declaration (declaration of any existing right in favour of a person if any cloud/obscurity veiled the said right)</li>
            </ul>
          </td>
        </tr>
        <tr>
          <td colSpan="2">
            <hr/>
          </td>
        </tr>
        <tr>
          <td>
            <div className="section-caption">
              <h5 className="custom-underline1">
                Suits under Easement Act
              </h5>
            </div>
          </td>
          <td className="description">
            <ul>
              <li>An easement pertaining to pathways</li>
              <li>An easement pertaining to drainage of water</li>
              <li>An easement pertaining to light and air</li>
              <li>An easement pertaining to lateral support, etc.</li>
            </ul>
          </td>
        </tr>
        <tr>
          <td colSpan="2">
            <hr/>
          </td>
        </tr>
        <tr>
          <td>
            <div className="section-caption">
              <h5 className="custom-underline1">
                Petitions for Succession Certificates
              </h5>
            </div>
          </td>
          <td className="description">
            When a person dies intestate, the legal heirs have to make a petition to the honourable Court to obtain a succession certificate entitling the holder to claim the assets of the deceased.
          </td>
        </tr>
        <tr>
          <td colSpan="2">
            <hr/>
          </td>
        </tr>
        <tr>
          <td>
            <div className="section-caption">
              <h5 className="custom-underline1">
                Suit for partition of immovable properties in intestate succession
              </h5>
            </div>
          </td>
          <td className="description">
            Co-owners of the property can institute partition suit before the appropriate Court in case dispute arises between them.
          </td>
        </tr>
        <tr>
          <td colSpan="2">
            <hr/>
          </td>
        </tr>
        <tr>
          <td>
            <div className="section-caption">
              <h5 className="custom-underline1">
                Suit for prohibitory/mandatory injunctions, boundary disputes and for fixation of boundary of properties
              </h5>
            </div>
          </td>
          <td className="description">
            A suit relating to prohibitory and mandatory injunctions against any breach of a civil right. If there is an omission, inconsistency or ambiguity with regard to the boundary between two properties, a suit can be filed for fixation of boundary.
          </td>
        </tr>           
      </tbody>
    </table>
  )
}