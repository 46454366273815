import React from 'react';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

import ServiceTemplate from '../service_template/ServiceTemplate';

import LandAquistionImage from '../../../images/land-aquisition-img.jpg';

export default function LandAquisition() {

  const { t } = useTranslation();
  
  return(
    <ServiceTemplate
      title={t('services.land_aquisition.title')}
      description={parse(t('services.land_aquisition.description'))}
      image={LandAquistionImage}
    />
  )
}
