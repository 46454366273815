import React from 'react';
import { useTranslation } from 'react-i18next';
import { faPhoneAlt } from "@fortawesome/free-solid-svg-icons";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Hidden from '@material-ui/core/Hidden';

import EnquiryModal from '../enquiry/EnquiryModal';

import './Contact.scss';

export default function Contact() {
  const { t } = useTranslation();

  return (
    <div>
      <div id = "contact">
        <div className="contact-content">
          <h2>CONTACT</h2>
          <div className='address'>
            {t('contact.address')}
            <Hidden mdUp>
              <a href="https://goo.gl/maps/ruGR4wF6TEtP2Bwn8" target='_blank' rel="noreferrer" title="click to open google map" >
                <FontAwesomeIcon icon={faMapMarkerAlt} />
              </a>
            </Hidden>
          </div>
          <Hidden smDown>
          <iframe title="gmap-address" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d63137.22730087084!2d76.942288!3d8.491923!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x5e8227e87e69d254!2sJN%20Law%20Associates!5e0!3m2!1sen!2sin!4v1643521832497!5m2!1sen!2sin" width="600" height="450" style={{border: 0}} allowfullscreen="" loading="lazy"></iframe>          </Hidden>
          <div>
            <div className="tel">
              <a href='tel:+919447428661' target='_blank' rel="noreferrer" title="click to open dialpad">
                <FontAwesomeIcon icon={faPhoneAlt} />
                <span>{t('contact.phone')}</span>
              </a>
            </div>
            <div className="whatsapp">
              <a href="https://api.whatsapp.com/send?phone=919447428661" target='_blank' rel="noreferrer" title="click to open whatsapp">
                <FontAwesomeIcon icon={faWhatsapp} />
                <span>{t('contact.phone')}</span>
              </a>
            </div>
            <div className="email">
              <a href="mailto:jnlawassociates@gmail.com" target='_blank' rel="noreferrer" title="click to open email">
                <FontAwesomeIcon icon={faEnvelope} />
                <span>{t('contact.email')}</span>
              </a>
            </div>
          </div>
        </div>
        <div className="timings-content">
          <h3>{t('contact.office_consultation_timings').toUpperCase()}</h3>
          <p>Mon to Sat (Except Second Saturdays)</p>
          <p>9:00AM to 5:00PM</p>
          <h3>{t('contact.phone_consultation_timings').toUpperCase()}</h3>
          <p>Mon to Sat (Except Second Saturdays)</p>
          <p>4:00PM to 7:00PM</p>
          <EnquiryModal variant="outlined"/>
        </div>
      </div>
      <div id='website-creator'>
        <span>Created by </span><a href="mailto:josh.nikhitha@gmail.com" target='_blank' rel="noreferrer"><b>Nikhitha Josh</b></a>
      </div>
    </div>    
  )
}