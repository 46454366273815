import React from 'react';
import Header from '../../../components/header/Header'
import { ScrollToTopOnMount } from '../../../components/ScrollToTopOnMount';

import './ServiceTemplate.scss';

export default function ServiceTemplate(props) {
  return (
    <div id={props.title.replace(/\s+/g, '-').toLowerCase()} className="service-template">
      <ScrollToTopOnMount />
      <Header />
      <div style={{backgroundImage: `url(${props.image})`}} className="service-image">
        <div className="image-caption">
          {props.title.toUpperCase()}
        </div>
      </div>
      <div data-aos="fade-up" className="service-wrapper">
        <div className="section">
          <div className="section-caption"><h2 className="custom-underline">{props.title}</h2></div>
          <div className="section-content">
            {props.description}
          </div>
        </div>
      </div>
    </div>
  )
}
