import React from 'react';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

import ServiceTemplate from '../service_template/ServiceTemplate';

import MotorAccidentImage from '../../../images/motor-accident-7.jpeg'

export default function MotorAccidentsClaim() {

  const { t } = useTranslation();
  
  return(
    <ServiceTemplate
      title={t('services.motor_accidents_claim.title')}
      description={parse(t('services.motor_accidents_claim.description'))}
      image={MotorAccidentImage}
    />
  )
}

