import React from 'react';
import parse from 'html-react-parser';

import Header from '../header/Header';
import { ScrollToTopOnMount } from '../ScrollToTopOnMount';

import './TermsAndConditions.scss'

export default function TermsAndConditions() {
  return (
    <div id="terms-and-conditions" className="service-template">
      <ScrollToTopOnMount />
      <Header />
      <div className="section-caption">
        <h2 className="custom-underline" style={{lineHeight: "3rem"}}>
          {"TERMS AND CONDITIONS & PRIVACY POLICY"}
        </h2>
      </div>
      <div data-aos="fade-up" className="disclaimer-wrapper">
        <pre>
          {parse(`
This Policy shall be construed to be provided in compliance with the Information Technology Act 2000 and the rules framed thereunder (as amended from time to time) ("IT Act"). The words and expressions used in this Policy but not defined herein will have the meaning assigned to them under the IT Act.

<b>Personal data</b>
Personal data is any information which is related to an identified or identifiable natural person. 
All the personal data provided to us by a user, including sensitive personal data, is voluntary and by sending your personal data to us, agreeing to receive our legal advice, through the website or any other mediums of communication, you are agreeing to this privacy policy.

We use the personal data, including personal data from other publicly available sources, for resolving/settling your legal issues, as directed by you.

We will use your personal data during the course of our professional activities, on the basis of your consent, performance of a contract, compliance with a legal obligation or legitimate interest, for the following:
<ul>
<li>attainment of our legal or regulatory obligations.</li>
<li>to verify whether you are entitled to access and use the Website and the services made available through the Website.</li>
<li>to provide legal advice or other services or things you may have requested, including online or legal technology services or solutions as instructed or requested by you or your organisation.</li>
<li>to comply with our legal obligations which may include automated checks of your contact data or other information you provide about your identity against applicable sanctioned-party lists and may contact you to confirm your identity in case of a potential match or recording interaction with you which may be relevant for compliance purposes.</li>
<li>to protect the security of and manage access to our premises, information technology and communication systems, online platforms, websites and other systems and to prevent and detect security threats, fraud or other criminal or malicious activities.</li>
<li>for insurance purposes.</li>
<li>to comply with our legal and regulatory obligations and requests anywhere in the world, including reporting to and/or being audited by national and international regulatory bodies.</li>
<li>to comply with court orders and exercise and/or defend JN Law Associates' legal rights.</li>
<li>for any purpose related and/or ancillary to any of the above or any other purpose for which your Personal Information was provided to us.</li>
</ul>
We may share your Personal Information with 
<ul>
<li>courts, law enforcement authorities, regulators or attorneys or other parties where it is reasonably necessary for JN Law Associates to exercise or defend a legal or equitable claim, or for the purposes of a confidential alternative dispute resolution process for resolving your legal issues and/or any other legal issues.</li>
<li>service providers within or outside JN Law Associates, domestically or abroad, e.g. shared service centres, to process personal data for Permitted Purposes on our behalf and in accordance with our instructions only. JN Law Associates will use appropriate safeguards as required by applicable law to ensure the integrity and security of your Personal Information when engaging such service providers.</li>
</ul>
As required under applicable Indian laws, your Personal Information will be kept confidential to the maximum possible extent and will be used to enhance your relationship with JN Law Associates.

There are generally no detrimental effects for you if you choose not to consent or to provide personal data.

If you provide Personal Information to us about someone else (such as one of your directors or employees, or someone with whom you have business dealings) you must ensure that you are entitled to disclose that Personal Information to us and that, without our taking any further steps, we may collect, use and disclose that Personal Information as described in this Policy. In particular, you must ensure the individual concerned is aware of the various matters detailed in this Policy, as those matters relate to that individual, including our identity, how to contact us, our purposes of collection, our personal data disclosure practices (including disclosure to overseas recipients), the individual's right to obtain access to the Personal Information and make complaints about the handling of the Personal Information, and the consequences if the Personal Information is not provided (such as our inability to provide services).

We have adopted measures to ensure that your Personal Information and Sensitive Personal Information is accessible to our employees or partners’ employees strictly on a need to know basis. You accept the inherent security implications of providing information over internet / cellular / data networks and will not hold JN Law Associates responsible for any breach of security or the disclosure of Personal Information unless JN Law Associates has been grossly and willfully negligent.

Your Personal Information will primarily be stored in an electronic form. However, certain data can also be stored in physical form. JN Law Associates may store, collect, process and use your Personal Information in countries other than the Republic of India but under full compliance with applicable laws. JN Law Associates may enter into an agreement with third parties (in or outside of India) to store collect, process your Personal Information and Sensitive Personal Information under full compliance with applicable laws.

Notwithstanding anything contained in this Policy or elsewhere, JN Law Associates shall not be held responsible for any loss, damage or misuse of your Personal Information, if such loss, damage or misuse is attributable to a Force Majeure Event. A "Force Majeure Event" shall mean any event that is beyond the reasonable control of JN Law Associates and shall include, inter alia, without limitation, sabotage, fire, flood, explosion, acts of God, civil commotion, strikes or industrial action of any kind, riots, insurrection, war, acts of government, computer hacking, unauthorized access to computer data and storage device, computer crashes, breach of security and encryption.

We strive hard to keep our records updated and accurate with your latest information. You shall be responsible to ensure that the information or data you provide from time to time is and shall be correct, current and updated and you have all the rights, permissions and consent to provide such information or data to us.

Based on technical feasibility and requirements of applicable laws, we will provide you with the right to review, access and modify the Personal Information or Sensitive Personal Information that we maintain about you. We may perform verification before providing you access to this Personal Information. However, we are not responsible for the authenticity of the Sensitive Personal Information provided by you.

You may note that correction or deletion of certain information or data may lead to cancellation of your registration with the Website or your access to certain features of the Website. You also agree and acknowledge that certain data or information cannot be corrected or deleted and/or is prohibited from being deleted under any applicable law or in lieu of law enforcement requests or under any judicial proceedings.

We are committed to protect your Personal Information collected and processed by us and look forward to your continued support for the same. In case of any feedback or concern regarding protection of your Personal Information, or any privacy related feedback or concerns you may contact us at <i>jnlawassociates@gmail.com</i>.

We welcome your views about our Website and our Policy. If you would like to contact us with any queries or comments, please send an email to <i>jnlawassociates@gmail.com</i> or send a letter to
  <i>Adv. Josh Rajan, 
  JN Law Associates, 
  2nd floor Rajenesh Buildings, 
  Opposite Judges Quarters, 
  East of District Court, 
  Vanchiyoor, Trivandrum</i>.

We may change our Privacy Policy. We will update it online.

`)}
        </pre>
      </div>
    </div>
  )
}
