import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {
  Link
} from "react-router-dom";

import './Card.scss';

const useStyles = makeStyles({
  media: {
    height: 140,
  },
});

export default function MediaCard(props) {

  const classes = useStyles();

  const card = props.title !== "" ? (
    <Card id="card" sx={{ flexGrow: 1 }}>
      <CardActionArea>
        <CardMedia
          className={classes.media}
          image={props.image}
          title="Contemplative Reptile"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {props.title}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {props.text}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
      <Button variant="outlined" color="primary">
          <Link to={props.url} className="btn btn-primary">Read More</Link>
      </Button>
      </CardActions>
    </Card>
  ) : (
    <div style={{width: 345, margin: '20px'}}></div>
  )

  return card;
}

