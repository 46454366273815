import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import { ROUTES } from '../../routes'

import './About.scss';

export default function About() {

  const { t } = useTranslation();

  return(
    <div id='about' name='about'>
      <div data-aos="fade-up">
        <div className="section-caption">
          <h2 className="custom-underline">
            {t('about.title')}
          </h2>
        </div>
        <p>
          <Trans
            i18nKey="about.description"
            t={t}
            components={{
              linkTag: <Link to={ROUTES["Credentials"].path}>here</Link>
            }}  
          />
        </p>
      </div>
    </div>
  )
}
