import React from 'react';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

import ServiceTemplate from '../service_template/ServiceTemplate';

import IntellectualPropertyImage from '../../../images/intellectual-property.jpg';

import './IntellectualProperty.scss'

export default function IntellectualProperty() {

  const { t } = useTranslation();
  
  return(
    <ServiceTemplate
      title={t('services.intellectual_property.title')}
      description={parse(t('services.intellectual_property.description'))}
      image={IntellectualPropertyImage}
    />
  )
}
